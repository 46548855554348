import React, { FC, useEffect, useState } from 'react';
import {
  propertyForm,
  addAdConversion,
  addUserDailyConversion,
} from 'data/DataController';
import Label from 'components/Label/Label';
import Textarea from 'shared/Textarea/Textarea';
import Input from 'shared/Input/Input';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

export interface ContactFormProps {
  className?: string;
  reference?: string;
  userId?: number;
  propertyId?: number;
  agentEmailAddress?: string;
  onEmailSent?: Function;
}

const ContactForm: FC<ContactFormProps> = ({
  className = '',
  reference,
  userId,
  propertyId,
  agentEmailAddress,
  onEmailSent,
}) => {
  // TODO: Language:
  //   const contextState = React.useContext(LanguageContext);
  //   const language = contextState.language;
  //   const Strings = Languages[language].texts;

  //   const { route } = props;
  //   const { navigation } = props;
  //   const { item } = route.params;

  //   const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [checked, setChecked] = useState(false);

  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackTextColor, setFeedbackTextColor] = useState('text-red-500');
  const [emailSentSuccesfully, setEmailSentSuccesFully] = useState(false);

  useEffect(() => {
    if (emailSentSuccesfully === true) {
      //@ts-ignore
      onEmailSent();
    }
  }, [emailSentSuccesfully]);

  const sendEmail = async () => {
    // console.log('Send email function runs');
    // console.log(name, email, phone, message, checked);
    // console.log(name && email && phone && message && checked != false);

    if (name && email && phone && message && checked !== false) {
      // console.log('Send email with: ');
      // console.log(
      //   'hu',
      //   propertyId,
      //   agentEmailAddress,
      //   name,
      //   email,
      //   phone,
      //   message,
      //   checked
      // );
      propertyForm(
        'hu',
        userId,
        propertyId,
        agentEmailAddress,
        name,
        email,
        phone,
        message
      ).then((response) => {
        if (response === 'success') {
          setFeedbackTextColor('text-green-500');
          setFeedbackText('Üzenet elküldve.');
          setEmailSentSuccesFully(true);
          addAdConversion('email_sent', propertyId, 'hu');
          addUserDailyConversion(propertyId, 3);
        } else if (response === 'not-sended') {
          setFeedbackTextColor('text-red-500');
          setFeedbackText('Hiba történt, az üzenet nem lett elküldve.');
          setEmailSentSuccesFully(false);
        } else if (response === 'incomplete') {
          setFeedbackTextColor('text-red-500');
          setFeedbackText('Hiba: Az űrlap nincs teljesen kitöltve.');
          setEmailSentSuccesFully(false);
        } else {
          setFeedbackTextColor('text-red-500');
          setFeedbackText('Hiba történt');
          setEmailSentSuccesFully(false);
        }
      });
    } else {
      setFeedbackTextColor('text-red-500');
      setFeedbackText('Kérlek tölts ki minden mezőt');
      setEmailSentSuccesFully(false);
    }
  };

  return (
    <div className={`${className}`}>
      <form className='grid grid-cols-1 gap-6' action='#' method='post'>
        <label className='block'>
          <Label>Az Ön neve</Label>

          <Input
            placeholder=''
            type='text'
            className='mt-1'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label className='block'>
          <Label>Email cím</Label>

          <Input
            type='email'
            placeholder='pelda@gmail.com'
            className='mt-1'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>

        <label className='block'>
          <Label>Telefonszám</Label>

          <Input
            type='text'
            placeholder='+36 20 123 4567'
            className='mt-1'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </label>

        <label className='block'>
          <Label>Üzenet szövege</Label>

          <Textarea
            className='mt-1'
            rows={6}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </label>

        <div className='block'>
          <input
            type='checkbox'
            // defaultChecked={checked}
            onClick={() => {
              setChecked(!checked);
            }}
          />
          <span className='ml-2 text-sm'>
            {'Elfogadom az Adatkezelési tájékoztatóban leírtakat.'}
          </span>
        </div>

        <div className='flex justify-between'>
          <div className={`${feedbackTextColor}`}>{feedbackText}</div>
          {!emailSentSuccesfully && (
            <ButtonPrimary
              // @ts-ignore
              onClick={(e: any) => {
                e.preventDefault();
                sendEmail();
              }}
            >
              Üzenet küldése
            </ButtonPrimary>
          )}
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
