// @ts-nocheck
import appConfig from 'config/appConfig';

const LIMIT_ITEMS = appConfig.LIMIT_ITEMS;
const FEATURED_LIMIT = 8;
const RECENT_LIMIT = 9;
const RELATED_LIMIT = 10;

export async function getAllProperties(
  // language?: string,
  // city?: number[],
  // zone?: string[],
  // type?: number[],
  // status?: string[],
  // condition?: string[],
  // bedrooms?: number,
  // bathrooms?: number,
  // minprice?: number,
  // maxprice?: number,
  // minarea?: number[],
  // maxarea?: number[],
  // showOffers?: boolean,
  // extras?: string[], // maybe int[]..
  // sortby?: string,
  // page?: number

  {
    language,
    city,
    district,
    county,
    zone,
    type,
    status,
    condition,
    houseinnercondition,
    houseoutercondition,
    all_type,
    monthlygas,
    monthlyelectricity,
    heating,
    wall_type,
    bedrooms,
    bathrooms,
    minprice,
    maxprice,
    minprice_m2,
    maxprice_m2,
    minarea,
    maxarea,
    min_balcony_area,
    max_balcony_area,
    min_terrace_area,
    max_terrace_area,
    min_plot_area,
    max_plot_area,
    min_construction_year,
    max_construction_year,
    min_inner_height,
    max_inner_height,
    move_in,
    parking,
    view,
    furnished,
    floors,
    floor_located,
    electricity,
    water,
    gas,
    canal,
    bathwc,
    showOffers,
    extras,
    bathroom_type,
    sortby,
    page,
    user_id,
  }
) {
  try {
    // console.log('DataController GetAllProperites params:');
    // console.log(
    //   language,
    //   city,
    //   zone,
    //   type,
    //   status,
    //   condition,
    //   all_type,
    //   heating,
    //   wall_type,
    //   bedrooms,
    //   bathrooms,
    //   minprice,
    //   maxprice,
    //   minarea,
    //   maxarea,
    //   showOffers,
    //   extras,
    //   bathroom_type,
    //   sortby,
    //   page
    // );

    const url = `${appConfig.URL}app/data_properties.php?lang=${language}&city=${city}&district=${district}&county=${county}&zone=${zone}&type=${type}&status=${status}&condition=${condition}&houseinnercondition=${houseinnercondition}&houseoutercondition=${houseoutercondition}&all_type=${all_type}&monthlygas=${monthlygas}&monthlyelectricity=${monthlyelectricity}&heating=${heating}&wall=${wall_type}&minbeds=${bedrooms}&minbaths=${bathrooms}&minprice=${minprice}&maxprice=${maxprice}&minprice_m2=${minprice_m2}&maxprice_m2=${maxprice_m2}&minarea=${minarea}&maxarea=${maxarea}&min_balcony_area=${min_balcony_area}&max_balcony_area=${max_balcony_area}&min_terrace_area=${min_terrace_area}&max_terrace_area=${max_terrace_area}&min_plot_area=${min_plot_area}&max_plot_area=${max_plot_area}&min_construction_year=${min_construction_year}&max_construction_year=${max_construction_year}&min_inner_height=${min_inner_height}&max_inner_height=${max_inner_height}&move_in=${move_in}&parking=${parking}&view=${view}&furnished=${furnished}&floors=${floors}&floor_located=${floor_located}&electricity=${electricity}&water=${water}&gas=${gas}&canal=${canal}&bathwc=${bathwc}&offers=${showOffers}&extras=${extras}&bathrooms=${bathroom_type}&sortby=${sortby}&limit=${LIMIT_ITEMS}&page=${page}&user_id=${user_id}`;
    let response = await fetch(url);
    let responseJson = await response.json();

    // console.log('DATA CONTROLLER PARAMS-->');
    // console.log(language, sortby, page);
    // console.log('DATA CONTROLLER -->');

    // console.log(url);
    // console.log(responseJson);
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

// export async function getRelatedProperties(
//   $connect,
//   $lang,
//   $price,
//   $type,
//   $city,
//   $property,
//   $limit
// ) {
//   try {
//     const url = `${appConfig.URL}app/data_related_properties.php?lang=${language}&featured=1&page=1&limit=${FEATURED_LIMIT}&order=desc`;
//     let response = await fetch(url);
//     let responseJson = await response.json();
//     return responseJson;
//   } catch (error) {
//     //console.error(error);
//   }
// }

export async function getUserData() {
  try {
    const url = `${appConfig.URL}app/data_session.php`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getFeaturedProperties(language) {
  try {
    const url = `${appConfig.URL}app/data_properties.php?lang=${language}&featured=1&page=1&limit=${FEATURED_LIMIT}&order=desc`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getRelatedProperties(
  language: string,
  latitude: number,
  longitude: number,
  price: string,
  type: number,
  property_id: number
) {
  try {
    price = price.replace(/\./g, '');
    price = price.replace(/Ft/g, '');

    const url = `${appConfig.URL}app/data_related_properties.php?lang=${language}&latitude=${latitude}&longitude=${longitude}&price=${price}&type=${type}&id=${property_id}&limit=${RELATED_LIMIT}&order=desc`;

    let response = await fetch(url);
    let responseJson = await response.json();

    return responseJson;
  } catch (error) {
    console.error('ERROR');
  }
}

export async function getCities(language: string) {
  try {
    const url = `${appConfig.URL}app/data_cities.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getCounties() {
  try {
    const url = `${appConfig.URL}app/data_counties.php?lang=hu`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getDistricts() {
  try {
    const url = `${appConfig.URL}app/data_districts.php?lang=hu`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getTypes(language: string) {
  try {
    const url = `${appConfig.URL}app/data_types.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getConditions(language: string) {
  try {
    const url = `${appConfig.URL}app/data_conditions.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getAllPropertyType(
  language: string,
  propertyType: number
) {
  // const typeSelect = document.getElementById('all_type_select');
  // document.getElementById('all_type_select').innerText = null;
  // data.forEach(element => typeSelect.add(new Option(element['title'], element['id'])));

  try {
    const url = `${appConfig.URL}app/data_all_type.php?lang=${language}&all_type=${propertyType}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getHeatings(language: string) {
  try {
    const url = `${appConfig.URL}app/data_heating.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getWallTypes(language: string) {
  try {
    const url = `${appConfig.URL}app/data_wall.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getMoveIn(language: string) {
  try {
    const url = `${appConfig.URL}app/data_move_in.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getParking(language: string) {
  try {
    const url = `${appConfig.URL}app/data_parking.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getView(language: string) {
  try {
    const url = `${appConfig.URL}app/data_view.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getFurnished(language: string) {
  try {
    const url = `${appConfig.URL}app/data_furnished.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getFloorLocated(language: string) {
  try {
    const url = `${appConfig.URL}app/data_floor_located.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getElectricity(language: string) {
  try {
    const url = `${appConfig.URL}app/data_electricity.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getWater(language: string) {
  try {
    const url = `${appConfig.URL}app/data_water.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getGas(language: string) {
  try {
    const url = `${appConfig.URL}app/data_gas.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getCanal(language: string) {
  try {
    const url = `${appConfig.URL}app/data_canal.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getExtras(language: string) {
  const url = `${appConfig.URL}app/data_extras.php?lang=${language}`;

  try {
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {}
}

export async function getBathrooms(language: string) {
  const url = `${appConfig.URL}app/data_bathrooms.php?lang=${language}`;

  try {
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {}
}

export async function getPropertyById(language, id) {
  try {
    const url = `${appConfig.URL}app/data_property.php?lang=${language}&id=${id}&limit=1`;
    let response = await fetch(url);
    let responseJson = await response.json();

    if (responseJson[0].tiktok_id) {
      responseJson[0].tiktok_id = responseJson[0].tiktok_id.replace('T', '');
    }
    //console.log(responseJson);
    return responseJson;
  } catch (error) {
    console.error(error);
  }
}

export async function getBathWC(language: string) {
  try {
    const url = `${appConfig.URL}app/data_bath_wc.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getGalleryByProperty(language, id) {
  try {
    const url = `${appConfig.URL}app/data_gallery.php?lang=${language}&id=${id}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getExtrasByProperty(language, id) {
  try {
    const url = `${appConfig.URL}app/data_extras.php?lang=${language}&id=${id}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getBathroomByProperty(language, id) {
  try {
    const url = `${appConfig.URL}app/data_bathrooms.php?lang=${language}&id=${id}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getPriceInEUR(hufAmount) {
  try {
    let priceInHUF = hufAmount;
    priceInHUF = priceInHUF.replace(/\./g, '');
    priceInHUF = priceInHUF.replace(/Ft/g, '');
    //('Connector price in huf:');
    // console.log(priceInHUF);
    //  const url = `https://api.exchangerate.host/latest?base=HUF&symbols=EUR&amount=${priceInHUF}&places=0`;
    const url = `${appConfig.URL}app/data_get_eur_price.php?lang=hu&huf_amount=${priceInHUF}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    // console.log('RESP PRICE CONNECTOR: ' + responseJson.rates.EUR);
    // if (responseJson.success !== true) {
    //   return 0;
    // }
    //return responseJson.rates.EUR;
    //const priceInEUR = responseJson.rates.EUR;
    const priceInEUR = responseJson;
    var parts = priceInEUR.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    parts.join(',');
    return '€ ' + parts[0];
  } catch (error) {
    //console.error(error);
  }
}

export async function propertyForm(
  language,
  user_id,
  id,
  agentemail,
  name,
  email,
  phone,
  message
) {
  //  const propertyurl = `${appConfig.URL}/single-property.php?id=${id}&lang=${language}`;
  const propertyurl = `${appConfig.URL}property/${id}`;
  const url = `${appConfig.URL}app/property_form.php?lang=${language}`;
  if (user_id) {
    try {
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-USER-ID': user_id,
        },
        body: JSON.stringify({
          property_url: propertyurl,
          agent_email: agentemail,
          user_name: name,
          user_email: email,
          user_phone: phone,
          user_message: message,
        }),
      });
      const json = await resp.json();
      return json;
    } catch (e) {
      // console.log('Error...', e.message);
    }
  } else {
    console.log('No userID provided');
  }
}

export async function getAgentByAgentId(language, agent_id) {
  try {
    const url = `${appConfig.URL}app/data_agent_by_agent_id.php?lang=${language}&agent_id=${agent_id}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getAgentByProperty(language, id) {
  try {
    const url = `${appConfig.URL}app/data_agents.php?lang=${language}&id=${id}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function addPageView(language, id) {
  try {
    const url = `${appConfig.URL}app/data_page_view.php?lang=${language}&id=${id}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getNumberOfProperties(language: string) {
  try {
    const url = `${appConfig.URL}app/data_number_of_properties.php?lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function addAdConversion(action, ad_id, language) {
  try {
    const url = `${appConfig.URL}app/data_ad_conversions.php?action=${action}&ad_id=${ad_id}&lang=${language}`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function addUserDailyConversion(property_id, conversion_type_id) {
  try {
    const url = `${appConfig.URL}app/data_ad_daily_conversion.php?property_id=${property_id}&conversion_type_id=${conversion_type_id}&lang=hu`;
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

// FUNCTIONS THAT REQUIRE header sesssion auth
export async function getAllFavorite(language, user_id) {
  try {
    const url = `${appConfig.URL}app/data_favorites.php?lang=${language}&action=get_all`;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-USER-ID': user_id,
      },
    };

    let response = await fetch(url, requestOptions);
    let responseJson = await response.json();
    // console.log('RESP ->');
    // console.log(responseJson);
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function getIsFavoriteByPropertyID(
  language,
  user_id,
  property_id
) {
  try {
    const url = `${appConfig.URL}app/data_favorites.php?lang=${language}&item=${property_id}&action=get_by_property_id`;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-USER-ID': user_id,
      },
    };

    // console.log('getIsFavoriteByPropertyID is called with userID:' + user_id);
    // console.log(url);

    let response = await fetch(url, requestOptions);
    let responseJson = await response.json();
    // console.log('RESP ->');
    // console.log(responseJson);
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function addFavoriteByID(language, user_id, property_id) {
  try {
    const url = `${appConfig.URL}app/data_favorites.php?lang=${language}&item=${property_id}&action=add`;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-USER-ID': user_id,
      },
    };

    let response = await fetch(url, requestOptions);
    let responseJson = await response.json();
    // console.log('RESP ->');
    // console.log(responseJson);
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}

export async function deleteFavoriteByID(language, user_id, property_id) {
  try {
    const url = `${appConfig.URL}app/data_favorites.php?lang=${language}&item=${property_id}&action=delete`;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-USER-ID': user_id,
      },
    };

    let response = await fetch(url, requestOptions);
    let responseJson = await response.json();
    // console.log('RESP ->');
    // console.log(responseJson);
    return responseJson;
  } catch (error) {
    //console.error(error);
  }
}
