// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { FC } from 'react';
// import Slider from 'rc-slider';
import { Range } from 'rc-slider';
import convertNumbThousand from 'utils/convertNumbThousand';

export interface PriceRangeInputProps {
  onChange?: (e: number[]) => void;
  defaultValue?: number[];
  rentMode?: boolean;
}

const PriceRangeInput: FC<PriceRangeInputProps> = ({
  onChange,
  defaultValue,
  rentMode,
}) => {
  const [rangePrices, setRangePrices] = useState(defaultValue);

  const setRangePricesHandler = () => {
    /* @ts-ignore */
    onChange(rangePrices);
  };

  // Its ugly but for performance -> If theres a range slide -> a lot state upgrade, a lot re-render
  // Theres no onAfterChange event on manual input -> It's needed
  const setRangePricesHandlerWithValue = (rangePrices: number[]) => {
    /* @ts-ignore */
    onChange(rangePrices);
  };

  const minValue = -1;
  const maxValue = 5000000;
  // @ts-ignore
  const [priceSuffix, setPriceSuffix] = useState('millió Ft');

  useEffect(() => {
    if (rentMode) {
      setRangePrices([0, 0]);
      setPriceSuffix('Ft /hó');
    } else {
      setRangePrices([0, 0]);
      setPriceSuffix('millió Ft');
    }
  }, [rentMode]);

  useEffect(() => {
    setRangePrices(defaultValue);
  }, []);

  return (
    <div className='p-5'>
      <span className='block font-semibold text-xl sm:text-2xl'>
        Ártartomány
      </span>
      <div className='relative flex flex-col space-y-8 mt-7'>
        {/* <Slider
          range
          className='text-red-400'
          min={10000}
          max={10000000}
          defaultValue={[rangePrices[0], rangePrices[1]]}
          allowCross={false}
          step={1000}
          onChange={(e) => {
            setRangePrices(e as number[]);
            onChange && onChange(e as number[]);
          }}
        /> */}

        {/* {!rentMode && (
          <Range
            className='text-red-400'
            min={0}
            max={300}
            defaultValue={[rangePrices[0], rangePrices[1]]}
            value={[rangePrices[0], rangePrices[1]]}
            allowCross={false}
            step={1}
            // onChange={setRangePrices}
            onChange={(e) => {
              setRangePrices(e as number[]);
              onChange && onChange(e as number[]);
            }}
            onAfterChange={setRangePricesHandler}
          />
        )}
        {rentMode && (
          <Range
            className='text-red-400'
            min={10000}
            max={1000000}
            defaultValue={[rangePrices[0], rangePrices[1]]}
            value={[rangePrices[0], rangePrices[1]]}
            allowCross={false}
            step={10000}
            // onChange={setRangePrices}
            onChange={(e) => {
              setRangePrices(e as number[]);
              onChange && onChange(e as number[]);
            }}
            onAfterChange={setRangePricesHandler}
          />
        )} */}

        <div className='flex justify-between space-x-3'>
          <div>
            <label
              htmlFor='minPrice'
              className='block text-sm font-medium text-neutral-700 dark:text-neutral-300'
            >
              Minimum ár
            </label>
            <div className='mt-1 relative rounded-md'>
              <input
                type='number'
                name='minPrice'
                id='minPrice'
                className='focus:ring-primary-500 focus:border-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900'
                value={rangePrices[0]}
                onChange={(e) => {
                  let newValue = 0;
                  if (e.target.value !== '') {
                    newValue = parseInt(e.target.value, 10).toString();
                    if (newValue < 0) {
                      newValue = 0;
                    }
                  }
                  if (minValue < newValue && newValue < maxValue) {
                    setRangePrices([newValue, rangePrices[1]]);
                    setRangePricesHandlerWithValue([newValue, rangePrices[1]]);
                  }
                }}
              />
              <div className='absolute inset-y-0 right-3 pl-3 flex items-center pointer-events-none'>
                <span className='text-neutral-500 sm:text-sm'>
                  {/*@ts-ignore */}
                  {priceSuffix}
                </span>
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor='maxPrice'
              className='block text-sm font-medium text-neutral-700 dark:text-neutral-300'
            >
              Maximum ár
            </label>
            <div className='mt-1 relative rounded-md'>
              <input
                type='number'
                name='maxPrice'
                id='maxPrice'
                className='focus:ring-primary-500 focus:border-priring-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900'
                value={rangePrices[1]}
                onChange={(e) => {
                  let newValue = 0;
                  if (e.target.value !== '') {
                    newValue = parseInt(e.target.value, 10).toString();
                    if (newValue < 0) {
                      newValue = 0;
                    }
                  }
                  if (minValue < newValue && newValue < maxValue) {
                    setRangePrices([rangePrices[0], newValue]);
                    setRangePricesHandlerWithValue([rangePrices[0], newValue]);
                  }
                }}
              />

              <div className='absolute inset-y-0 right-3 pl-3 flex items-center pointer-events-none'>
                <span className='text-neutral-500 sm:text-sm'>
                  {/*@ts-ignore */}
                  {priceSuffix}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceRangeInput;
