import React, { FC, useState } from 'react';

export interface BtnMarkerIconProps {
  className?: string;
  onPress?: any;
}

const BtnMarkerIcon: FC<BtnMarkerIconProps> = ({ className = '', onPress }) => {
  return (
    <div
      className={`nc-BtnLikeIcon w-8 h-8 flex items-center justify-center rounded-full cursor-pointer ${className}`}
      data-nc-id='BtnLikeIcon'
      title='See on map'
      // onClick={() => setLikedState(!likedState)}
      onClick={() => onPress()}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='h-11 w-11'
        viewBox='0 0 20 20'
        fill='#17BED0'
      >
        <path
          fillRule='evenodd'
          d='M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z'
          clipRule='evenodd'
        />
      </svg>
    </div>
  );
};

export default BtnMarkerIcon;
