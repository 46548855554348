import React, { FC, useRef, useState, useEffect } from 'react';

export interface ShareFavoriteBtn {
  className?: string;
  colorClass?: string;
  isFavorited?: boolean;
  onFavoriteChange?: (isFavorited: boolean) => void;
}

const ShareFavoriteBtn: FC<ShareFavoriteBtn> = ({
  className = '',
  colorClass = 'text-white bg-black bg-opacity-30 hover:bg-opacity-50',
  isFavorited = false,
  onFavoriteChange,
}) => {
  const [copyText, setCopyText] = useState('Megosztás');
  const [favoriteText, setFavoriteText] = useState('Kedvencekhez adom');
  const textAreaRef = useRef(null);

  async function copyToClip() {
    await navigator.clipboard.writeText(window.location.href);
    setCopyText('Link másolva');
  }

  const handleClickOnFavorite = () => {
    const newFavorited = !isFavorited;
    console.log('Onchange parent: ');
    console.log(newFavorited);
    onFavoriteChange && onFavoriteChange(newFavorited);
  };

  useEffect(() => {
    if (isFavorited) {
      setFavoriteText('Kedvenc');
    } else {
      setFavoriteText('Kedvencekhez adom');
    }
  }, [isFavorited]);

  return (
    <div className='flow-root'>
      <div className='flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5'>
        <span
          className='py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer'
          onClick={copyToClip}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={1.5}
              d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12'
            />
          </svg>
          <span className='hidden sm:block ml-2.5'>{copyText}</span>
        </span>
        <span
          className='py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer select-none'
          onClick={handleClickOnFavorite}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className={`h-5 w-5 ${isFavorited ? 'text-red-500' : ''}`}
            fill={`${isFavorited ? 'red' : 'white'}`}
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={1.5}
              d='M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z'
            />
          </svg>
          <span className='hidden sm:block ml-2.5'>{favoriteText}</span>
        </span>
      </div>
    </div>
  );
};

export default ShareFavoriteBtn;
