import React, { FC, ReactNode, useState, useEffect } from 'react';
// import { DEMO_STAY_LISTINGS } from 'data/listings';
// import { StayDataType } from 'data/types';
// import ButtonPrimary from 'shared/Button/ButtonPrimary';
import HeaderFilter from './HeaderFilter';
import PropertyCardH from 'components/PropertyCardH/PropertyCardH';
import { getFeaturedProperties, getAllProperties } from 'data/DataController';
import { PropertiesContext } from 'context/PropertiesContext';

export interface SectionGridFeaturePropertyProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeatureProperty: FC<SectionGridFeaturePropertyProps> = ({
  gridClass = '',
  heading = 'További Ingatlanjaink',
  subHeading = ' ',
  headingIsCenter,
  tabs = ['Kiemelt Ingatlanok', 'Legutóbbi Ingatlanok'],
}) => {
  const [featuredProperties, setFeaturedProperties] = useState([]);
  const [latestProperties, setLatestProperties] = useState([]);
  const [latestPropertiesWithFavorites, setLatestPropertiesWithFavorites] =
    useState([]);
  const [propertiesToShow, setPropertiesToShow] = useState(featuredProperties);
  const [propertiesTypeToShow, setPropertiesTypeToShow] = useState(0);
  const PropertiesContextState = React.useContext(PropertiesContext);

  useEffect(() => {
    getFeaturedProperties('hu').then((response) => {
      setFeaturedProperties(response.properties_data);
    });

    // getAllProperties({
    //   language: 'hu',
    //   city: '',
    //   district: '',
    //   county: '',
    //   zone: '',
    //   type: '',
    //   status: '',
    //   condition: '',
    //   houseinnercondition: 0,
    //   houseoutercondition: 0,
    //   all_type: '',
    //   monthlygas: 0,
    //   monthlyelectricity: 0,
    //   heating: '',
    //   wall_type: '',
    //   bedrooms: 0,
    //   bathrooms: 0,
    //   minprice: 1,
    //   maxprice: 100000000000,
    //   minprice_m2: 0,
    //   maxprice_m2: 10000000,
    //   minarea: 1,
    //   maxarea: 1000,
    //   min_balcony_area: 0,
    //   max_balcony_area: 1000,
    //   min_terrace_area: 0,
    //   max_terrace_area: 1000,
    //   min_plot_area: 0,
    //   max_plot_area: 1000000,
    //   min_construction_year: 0,
    //   max_construction_year: 2050,
    //   min_inner_height: 0,
    //   max_inner_height: 10000,
    //   move_in: '',
    //   parking: '',
    //   view: '',
    //   furnished: '',
    //   floors: 0,
    //   floor_located: '',
    //   electricity: '',
    //   water: '',
    //   gas: '',
    //   canal: '',
    //   bathwc: '',
    //   showOffers: 0,
    //   extras: '',
    //   bathroom_type: '',
    //   sortby: '',
    //   page: 1,
    //   user_id: '',
    // }).then((response) => {
    //   let propertiesData = response.properties_data;
    //   console.log('Featured property: ');
    //   console.log(PropertiesContextState.favorites.length);
    //   if (PropertiesContextState.favorites.length >= 1) {
    //     propertiesData = propertiesData.map((ad: any) => {
    //       const isFavorite = PropertiesContextState.favorites?.some(
    //         (favorite: any) => favorite.item === ad.id
    //       );
    //       return { ...ad, isFavorite };
    //     });
    //   }
    //   //console.log('Set latest properties: ');
    //   //console.log(response);
    //   // setLatestProperties(propertiesData);
    // });
  }, []);

  useEffect(() => {
    //  console.log('==============================>>>>>>>>');
    //  console.log('Featured mounted');
    // console.log(PropertiesContextState);

    getAllProperties({
      language: 'hu',
      city: '',
      district: '',
      county: '',
      zone: '',
      type: '',
      status: '',
      condition: '',
      houseinnercondition: 0,
      houseoutercondition: 0,
      all_type: '',
      monthlygas: 0,
      monthlyelectricity: 0,
      heating: '',
      wall_type: '',
      bedrooms: 0,
      bathrooms: 0,
      minprice: 1,
      maxprice: 100000000000,
      minprice_m2: 0,
      maxprice_m2: 10000000,
      minarea: 1,
      maxarea: 1000,
      min_balcony_area: 0,
      max_balcony_area: 1000,
      min_terrace_area: 0,
      max_terrace_area: 1000,
      min_plot_area: 0,
      max_plot_area: 1000000,
      min_construction_year: 0,
      max_construction_year: 2050,
      min_inner_height: 0,
      max_inner_height: 10000,
      move_in: '',
      parking: '',
      view: '',
      furnished: '',
      floors: 0,
      floor_located: '',
      electricity: '',
      water: '',
      gas: '',
      canal: '',
      bathwc: '',
      showOffers: 0,
      extras: '',
      bathroom_type: '',
      sortby: '',
      page: 1,
      user_id: PropertiesContextState.userId,
    }).then((response) => {
      let propertiesData = response.properties_data;
      // console.log('propertiesData: ');
      // console.log(propertiesData);
      // console.log('Favorites length: ');
      // console.log(PropertiesContextState.favorites.length);
      if (PropertiesContextState.favorites.length >= 1) {
        propertiesData = propertiesData.map((ad: any) => {
          const isFavorite = PropertiesContextState.favorites?.some(
            (favorite: any) => favorite.item === ad.id
          );
          return { ...ad, isFavorite };
        });
      }

      // console.log(latestProperties);
      // console.log(PropertiesContextState.favorites);
      // let propertiesData: any = latestProperties;
      // if (PropertiesContextState.favorites.length >= 1) {
      //   propertiesData = propertiesData.map((ad: any) => {
      //     const isFavorite = PropertiesContextState.favorites?.some(
      //       (favorite: any) => favorite.item === ad.id
      //     );
      //     return { ...ad, isFavorite };
      //   });
      // }
      // console.log(propertiesData);
      setLatestPropertiesWithFavorites(propertiesData);

      //console.log('Set latest properties: ');
      //console.log(response);
      // setLatestProperties(propertiesData);
    });
  }, [PropertiesContextState.favorites]);

  useEffect(() => {
    if (propertiesTypeToShow === 0) {
      setPropertiesToShow(featuredProperties);
    } else {
      setPropertiesToShow(latestPropertiesWithFavorites);
    }
  }, [propertiesTypeToShow]);

  useEffect(() => {
    setPropertiesToShow(featuredProperties);
  }, [featuredProperties]);

  // const renderCard = (stay: StayDataType, index: number) => {
  //   return <PropertyCardH key={index} className='h-full' data={stay} />;
  // };

  return (
    <div className='nc-SectionGridFeatureProperty relative'>
      <HeaderFilter
        tabActive={'Kiemelt Ingatlanok'}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={(e) => {
          setPropertiesTypeToShow(e);
        }}
      />
      <div
        className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 ${gridClass}`}
      >
        {/* {DEMO_DATA.map(renderCard)} */}
        {
          // @ts-ignore
          propertiesToShow.map((stay) => (
            <PropertyCardH
              // @ts-ignore
              key={stay.id}
              data={stay}
              showCity={true}
              gridColNumber={2}
              gapNumber={2}
              showFeatured={false}
              // @ts-ignore
              isLiked={stay.isFavorite}
            />
          ))
        }
      </div>
      {/* <div className='flex mt-16 justify-center items-center'>
        <ButtonPrimary loading>Összes megtekintése</ButtonPrimary>
      </div> */}
    </div>
  );

  // return (
  //   featuredProperties && (
  //     <div
  //       className={`nc-SectionGridFilterCard ${className}`}
  //       data-nc-id='SectionGridFilterCard'
  //     >
  //       <Heading2 heading='Kiemelt ingatlanjaink' subHeading=' ' />

  //       <div className='grid grid-cols-1 gap-6 md:gap-8 xl:grid-cols-2 '>
  //         {
  //           // @ts-ignore
  //           featuredProperties.map((stay) => (
  //             // @ts-ignore
  //             <PropertyCardH key={stay.id} data={stay} />
  //           ))
  //         }
  //       </div>
  //     </div>
  //   )
  // );
};

export default SectionGridFeatureProperty;
