import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Page } from './types';
import ScrollToTop from './ScrollToTop';
import Footer from 'shared/Footer/Footer';
import Page404 from 'containers/Page404/Page404';
import ListingStayPage from 'containers/ListingStayPage/ListingStayPage';
import ListingStayMapPage from 'containers/ListingStayPage/ListingStayMapPage';
import ListingExperiencesPage from 'containers/ListingExperiencesPage/ListingExperiencesPage';
import ListingExperiencesMapPage from 'containers/ListingExperiencesPage/ListingExperiencesMapPage';
import ListingStayDetailPage from 'containers/ListingDetailPage/ListingStayDetailPage';
import ListingExperiencesDetailPage from 'containers/ListingDetailPage/ListingExperiencesDetailPage';
import ListingCarDetailPage from 'containers/ListingDetailPage/ListingCarDetailPage';
import PageSubcription from 'containers/PageSubcription/PageSubcription';
import ListingRealEstateMapPage from 'containers/ListingRealEstatePage/ListingRealEstateMapPage';
import ListingRealEstatePage from 'containers/ListingRealEstatePage/ListingRealEstatePage';
import SiteHeader from 'containers/SiteHeader';
import PageSearch from 'containers/PageSearch/PageSearch';

export const pages: Page[] = [
  { path: '/', exact: true, component: ListingRealEstateMapPage },
  { path: '/listing-stay', component: ListingStayPage },
  { path: '/listing-stay-map', component: ListingStayMapPage },

  // { path: '/property/:id', component: ListingStayDetailPage },
  { path: '/property/:id/:agent_id?', component: ListingStayDetailPage },

  {
    path: '/listing-experiences',
    component: ListingExperiencesPage,
  },
  {
    path: '/listing-experiences-map',
    component: ListingExperiencesMapPage,
  },
  {
    path: '/listing-experiences-detail',
    component: ListingExperiencesDetailPage,
  },
  //
  { path: '/listing-car-detail', component: ListingCarDetailPage },
  //

  { path: '/listing-real-estate', component: ListingRealEstatePage },
  //
  { path: '/subscription', component: PageSubcription },
  //
  { path: '/search-page', component: PageSearch },
];

const Routes = () => {
  return (
    <BrowserRouter>
      {/* <ScrollToTop /> */}
      {/* <SiteHeader /> */}

      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      {/* <Footer /> */}
    </BrowserRouter>
  );
};

export default Routes;
