import React, { FC } from 'react';
import GallerySlider from 'components/GallerySlider/GallerySlider';
import { DEMO_STAY_LISTINGS } from 'data/listings';
import { StayDataType } from 'data/types';
import StartRating from 'components/StartRating/StartRating';
import { Link } from 'react-router-dom';
import BtnLikeIcon from 'components/BtnLikeIcon/BtnLikeIcon';
import SaleOffBadge from 'components/SaleOffBadge/SaleOffBadge';
import Badge from 'shared/Badge/Badge';
import NcImage from 'shared/NcImage/NcImage';
import appConfig from 'config/appConfig';

export interface StayCardProps {
  className?: string;
  props?: any;
  data?: any;
  cardSize?: 'default' | 'small';
}

const StayCard: FC<StayCardProps> = ({
  className = '',
  data,
  cardSize = 'default',
}) => {
  const {
    address,
    baths,
    beds,
    city,
    discount,
    garages,
    image,
    latitude,
    longitude,
    oldprice,
    price,
    rating,
    size,
    status,
    title,
    type,
    id,
  } = data;

  const propertyLink = `${appConfig.URL}property/${id}`;

  let priceSalePercent: string;

  if (oldprice && oldprice != 0) {
    const priceoldNum = oldprice.match(/\d/g).join('');
    const priceNum = price.match(/\d/g).join('');

    let priceSalePercentNum = Math.round(
      ((priceoldNum - priceNum) / priceoldNum) * 100
    );

    if (1 <= priceSalePercentNum) {
      priceSalePercent =
        Math.round(((priceoldNum - priceNum) / priceoldNum) * 100) +
        '%' +
        ' árzuhanás';
    }
  }

  const originalSrc = image;

  // Function to modify the source
  function convertToThumbnail(src: any) {
    const parts = src.split('.');
    const extension = parts.pop();
    return `${parts.join('.')}_1280w.${extension}`;
  }

  const thumbnailSrc = convertToThumbnail(originalSrc);

  const renderPicture = () => {
    return (
      <div className='relative w-full'>
        {/* <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass='aspect-w-4 aspect-h-3 '
          galleryImgs={galleryImgs}
        /> */}

        <div className='aspect-w-1 aspect-h-1'>
          <NcImage
            src={thumbnailSrc}
            className='object-cover w-full h-full rounded-2xl'
          />
        </div>

        {/* <BtnLikeIcon isLiked={like} className='absolute right-3 top-3 z-[1]' /> */}
        {oldprice && priceSalePercent && (
          <SaleOffBadge
            className='absolute left-5 top-5 !bg-orange-500'
            desc={priceSalePercent}
          />
        )}
        {/* <div className='absolute left-2 top-2 '>
          <Badge
            name={
              <div className='flex items-center'>
                <i className='text-sm las la-tag'></i>
                <span className='ml-1'>{status}</span>
              </div>
            }
          />
          <Badge
            className='ml-2'
            name={
              <div className='flex items-center'>
                <i className=' text-sm las la-home'></i>
                <span className='ml-1'>{type}</span>
              </div>
            }
            color='green'
          />
          <Badge
            className='ml-2'
            name={
              <div className='flex items-center'>
                <i className='text-sm las la-map-marker-alt'></i>
                <span className='ml-1'>{city}</span>
              </div>
            }
            color='red'
          />
        </div> */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div
        className={cardSize === 'default' ? 'p-4 space-y-4' : 'p-3 space-y-2'}
      >
        <div className='space-y-2'>
          <div className='inline-flex space-x-3'>
            {/* <Badge
              name={
                <div className='flex items-center'>
                  <i className='text-sm las la-tag'></i>
                  <span className='ml-1'>{status}</span>
                </div>
              }
            /> */}
            <Badge
              name={
                <div className='flex items-center'>
                  <i className=' text-sm las la-home'></i>
                  <span className='ml-1'>{type}</span>
                </div>
              }
              color='turquoise'
            />

            <Badge
              className='ml-2'
              name={
                <div className='flex items-center'>
                  <i className='text-sm las la-map-marker-alt'></i>
                  <span className='ml-1'>{city}</span>
                </div>
              }
              color='turquoise'
            />
          </div>

          <div className='flex items-center space-x-2'>
            <h2
              className={` font-medium capitalize ${
                cardSize === 'default' ? 'text-lg' : 'text-base'
              }`}
            >
              <span className='line-clamp-1'>{title}</span>
            </h2>
          </div>

          <div className='inline-grid grid-cols-2 gap-2'>
            <div className='flex items-center space-x-2'>
              <span className='sm:inline-block'>
                <i className='las la-bed text-lg'></i>
              </span>
              <span className='text-xs text-neutral-500 dark:text-neutral-400'>
                {beds + ' szoba'}
              </span>
            </div>
            <div className='flex items-center space-x-2'>
              <span className=' sm:inline-block'>
                <i className='las la-bath text-lg'></i>
              </span>
              <span className='text-xs text-neutral-500 dark:text-neutral-400'>
                {baths + ' fürdőszoba'}
              </span>
            </div>
            <div className='flex items-center space-x-2'>
              <span className=' sm:inline-block'>
                <i className='las la-expand-arrows-alt text-lg'></i>
              </span>
              <span className='text-xs text-neutral-500 dark:text-neutral-400'>
                {size}
              </span>
            </div>
          </div>

          {/* <div className='flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2'>
            {cardSize === 'default' && (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={1.5}
                  d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
                />
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={1.5}
                  d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
                />
              </svg>
            )}
            <span className=''>{address}</span>
          </div> */}
        </div>
        <div className='w-14 border-b border-neutral-100 dark:border-neutral-800'></div>
        <div className='flex justify-between items-center'>
          {/* <span className='text-base font-semibold'>
            {price}
            {cardSize === 'default' && false && (
              <span className='text-sm text-neutral-500 dark:text-neutral-400 font-normal'>
                /hó
              </span>
            )}
          </span> */}
          <span className='flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500'>
            {/* TODO: ha for rent akkor / month-ot éár mögé írni -> type nak a id-je kéne nem 'for rent' stringel matcehlni, multialng lesz */}
            {price}
          </span>
          {/* {!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )} */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id='StayCard'
    >
      <a
        href={propertyLink}
        // className='h-full w-full flex flex-col sm:flex-row sm:items-center'
      >
        {renderPicture()}
        {renderContent()}
      </a>
      {/* </Link> */}
    </div>
  );
};

export default StayCard;
