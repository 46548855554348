import React, { FC } from 'react';

export interface SaleOffBadgeProps {
  className?: string;
  desc?: string;
  colorClass?: string;
}

const SaleOffBadge: FC<SaleOffBadgeProps> = ({
  className = '',
  desc,
  colorClass = 'bg-secondary-500',
}) => {
  return (
    <div
      className={`nc-SaleOffBadge flex items-center justify-center text-xs py-1.5 px-3 ${colorClass} text-red-50 rounded-full ${className}`}
      data-nc-id='SaleOffBadge'
    >
      {desc}
    </div>
  );
};

export default SaleOffBadge;
