import ButtonPrimary from 'shared/Button/ButtonPrimary';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import NcImage from 'shared/NcImage/NcImage';
import I404Png from 'images/404.png';
import PropertyNotFoundPng from 'images/property_not_found.png';
import Property404Png from 'images/404-property.png';

export interface PagePropertyNotActiveProps {
  description?: string;
  status?: string;
}

const PagePropertyNotActive: FC<PagePropertyNotActiveProps> = ({
  description = 'A keresett hirdetés jelenleg nem aktív állapotú, nézz vissza később.',
  status,
}) => {
  return (
    <div className='nc-Page404'>
      <Helmet>
        <title>A hirdetés jelenleg nem aktív || Multihome Ingatlan</title>
      </Helmet>
      <div className='flex items-center justify-center h-screen'>
        <div className='bg-white p-6 rounded-lg shadow-md'>
          <div className='container relative pt-5 pb-16 lg:pb-20 lg:pt-5'>
            {/* HEADER */}
            <header className='text-center max-w-2xl mx-auto space-y-2'>
              {status && status == 'sold' ? (
                <NcImage src={Property404Png} />
              ) : (
                <NcImage src={PropertyNotFoundPng} />
              )}

              <span className='block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium'>
                {description}
              </span>
              <div className='pt-8'>
                <ButtonPrimary href='/'>Visszatérés a főoldalra</ButtonPrimary>
              </div>
            </header>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagePropertyNotActive;
