import React, { FC, useState, useEffect } from 'react';

export interface BtnLikeIconProps {
  className?: string;
  colorClass?: string;
  isLiked?: boolean;
  onPress?: any;
}

const BtnLikeIcon: FC<BtnLikeIconProps> = ({
  className = '',
  colorClass = 'text-white bg-black bg-opacity-30 hover:bg-opacity-50',
  isLiked = false,
  onPress,
}) => {
  const [likedState, setLikedState] = useState(isLiked);

  // useEffect(() => {
  //   console.log(
  //     'UserID changed useEFF to: ' + userID + 'while propertyID is: ' + id
  //   );
  //   if (userID && id) {
  //     getIsFavoriteByPropertyID(lang, userID, id).then((response) => {
  //       console.log('USER FAVORITES RESP ->');
  //       console.log(response);

  //       if (response) {
  //         setIsFavorited(response);
  //       }
  //     });
  //   }
  // }, [userID]);

  // useEffect(() => {
  //   console.log('Liked state changed to:');
  //   console.log(likedState);

  //   if(likedState){
  //     addFavoriteByID('hu',1,)
  //   }else{

  //   }
  // }, [likedState]);

  return (
    <div
      className={`nc-BtnLikeIcon w-8 h-8 flex items-center justify-center rounded-full cursor-pointer ${
        likedState ? 'nc-BtnLikeIcon--liked' : ''
      }  ${colorClass} ${className}`}
      data-nc-id='BtnLikeIcon'
      title='Kedvencekhez adom'
      //onClick={() => setLikedState(!likedState)}
      onClick={() => {
        onPress(!likedState);
        setLikedState(!likedState);
      }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='h-5 w-5'
        fill={likedState ? 'red' : 'white'}
        viewBox='0 0 24 24'
        stroke={likedState ? 'red' : 'grey'}
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.5}
          d='M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z'
        />
      </svg>
    </div>
  );
};

export default BtnLikeIcon;
