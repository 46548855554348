// @ts-nocheck
import React, { FC, Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowRightIcon } from '@heroicons/react/outline';
import LocationMarker from 'components/AnyReactComponent/LocationMarker';
import CommentListing from 'components/CommentListing/CommentListing';
import FiveStartIconForRate from 'components/FiveStartIconForRate/FiveStartIconForRate';
import GuestsInput from 'components/HeroSearchForm/GuestsInput';
// import StayDatesRangeInput from 'components/HeroSearchForm/StayDatesRangeInput';
// import { DateRage } from 'components/HeroSearchForm/StaySearchForm';
import StartRating from 'components/StartRating/StartRating';
// import GoogleMapReact from 'google-map-react';
import useWindowSize from 'hooks/useWindowResize';
import moment from 'moment';
// import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import ButtonCircle from 'shared/Button/ButtonCircle';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import Input from 'shared/Input/Input';
import NcImage from 'shared/NcImage/NcImage';
import LikeSaveBtns from './LikeSaveBtns';
import ShareFavoriteBtn from './ShareFavoriteBtn';
import ModalPhotos from './ModalPhotos';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import { useParams } from 'react-router-dom';
import {
  getPropertyById,
  getGalleryByProperty,
  getExtrasByProperty,
  getBathroomByProperty,
  getPriceInEUR,
  getRelatedProperties,
  getAgentByAgentId,
  getAgentByProperty,
  addPageView,
  getUserData,
  getIsFavoriteByPropertyID,
  addFavoriteByID,
  deleteFavoriteByID,
  addAdConversion,
  addUserDailyConversion,
} from 'data/DataController';
import ButtonSubmit from 'components/HeroSearchForm/ButtonSubmit';
import ButtonThird from 'shared/Button/ButtonThird';
import appConfig from 'config/appConfig';
import PropertyCardH from 'components/PropertyCardH/PropertyCardH';
import { Link } from 'react-router-dom';
import Button from 'shared/Button/Button';
import Label from 'components/Label/Label';
import Textarea from 'shared/Textarea/Textarea';
import ContactForm from 'components/ContactForm/ContactForm';
import StayCard from 'components/StayCard/StayCard';
import SectionRelatedSlider from 'components/SectionRelatedSlider/SectionRelatedSlider';
import PropertyTypeSelect from 'components/HeroSearchForm/PropertyTypeSelect';
import PagePropertyNotActive from 'containers/Page404/PagePropertyNotActive';

export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const extra_icons = [
  { id: 1, icon: 'la-fan' },
  { id: 2, icon: 'la-luggage-cart' },
  { id: 3, icon: 'la-parking' },
  { id: 4, icon: 'la-cocktail' },
];

const ListingStayDetailPage: FC<ListingStayDetailPageProps> = ({
  className = '',
  isPreviewMode,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userID, setUserID] = useState();
  const [isFavorited, setIsFavorited] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const [property, setProperty] = useState([]);
  const [propertySizeString, setPropertySizeString] = useState(
    'Ingatlan alapterülete'
  );
  const [propertyImages, setPropertyImages] = useState([]);
  const [propertyAgent, setPropertyAgent] = useState([]);
  const [propertyAgentPhone, setPropertyAgentPhone] = useState();
  const [showFullPhoneNumber, setShowFullPhoneNumber] = useState(false);
  const [relatedProperties, setRelatedProperties] = useState([]);
  const [propertyExtras, setPropertyExtras] = useState([]);
  const [priceInEur, setPriceInEur] = useState();

  const featuredImage = property.image;

  let images = featuredImage;

  if (propertyImages.length >= 0) {
    const propertyImagesURLStrings = propertyImages.map(function (item: any) {
      return item['url'];
    });

    images = [featuredImage, ...propertyImagesURLStrings];
  }

  const [isOpenModalContact, setIsOpenModalContact] = useState(false);
  const [isOpenModalEmailSent, setIsOpenModalEmailSent] = useState(false);
  const [isOpenModalUserNotLoggedIn, setIsOpenModalUserNotLoggedIn] =
    useState(false);

  const windowSize = useWindowSize();

  // @ts-ignore
  let { id, agent_id } = useParams();

  const [adId, setAdId] = useState(id);
  const [agentId, setAgentId] = useState(agent_id);

  // console.log(id);
  // console.log(user_id);
  // console.log(user_id);

  // TODO: get lang from cookie or context or set it to internal window storage at first load?
  // TODO: language in the dependency array
  const lang = 'hu';
  // Get the array of ad IDs from local storage
  let phoneNumberViewedAtAdID =
    JSON.parse(localStorage.getItem('phoneNumberViewedAtAdID')) || [];

  useEffect(() => {
    getPropertyById(lang, id).then((response) => {
      try {
        setProperty(response[0]);
      } catch {
        console.log('RESP CATCH');
        setProperty([false]);
      }
    });

    getGalleryByProperty(lang, id).then((response) => {
      setPropertyImages(response);
    });

    const formatPhoneNumber = (phoneNumber) => {
      let formattedPhoneNumber;
      let cleaned = ('' + phoneNumber).replace(/\D/g, '');
      let match = cleaned.match(/^(36|06)?([1-9]\d{1,2})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? '+36 ' : '';
        formattedPhoneNumber = [
          intlCode,
          match[2],
          ' ',
          match[3],
          ' ',
          match[4],
        ].join('');
      } else {
        formattedPhoneNumber = phoneNumber;
      }
      return formattedPhoneNumber;
    };

    if (!agentId) {
      // Specific agent id not provided so use the property's seller by default
      getAgentByProperty(lang, id).then((response) => {
        setPropertyAgent(response[0]);
        // console.log('Property agent data:');
        // console.log(response[0]);
        if (response[0].user_phone && response[0].user_phone !== '') {
          // Format phonenumber
          setPropertyAgentPhone(formatPhoneNumber(response[0].user_phone));
        } else {
          setPropertyAgentPhone('+36 20 619 9696');
        }
      });
    } else {
      // Specific agent id is provided so use the params agent_id to get the seller's data
      getAgentByAgentId(lang, agent_id).then((response) => {
        setPropertyAgent(response[0]);
        if (response[0].user_phone && response[0].user_phone !== '') {
          // Format phonenumber
          setPropertyAgentPhone(formatPhoneNumber(response[0].user_phone));
        } else {
          setPropertyAgentPhone('+36 20 619 9696');
        }
      });
    }

    getExtrasByProperty(lang, id).then((response) => {
      setPropertyExtras(response);
    });

    getUserData().then((response) => {
      setIsLoggedIn(response[0].signed_in);
      setUserID(response[0].user_id);
    });

    //only count 1 user once a day

    // Get the ad ID and date from the page
    //let adId = id; // Replace with the actual ad ID

    //console.log('AD ID IS SETTED TO: ' + id);
    const currentDate = new Date().toLocaleDateString(); // Get the current date in a format that can be compared to localStorage

    // Check if the ad has already been viewed today
    const viewedAds = JSON.parse(localStorage.getItem('viewedAds')) || {};
    if (viewedAds[adId] !== currentDate) {
      // Send a request to increment the view count for the ad
      addPageView(lang, id);
      // Store the ID and current date in localStorage to avoid counting the view multiple times in one day
      viewedAds[adId] = currentDate;
      localStorage.setItem('viewedAds', JSON.stringify(viewedAds));
    }

    // Set if the user previously clicked on the mobile number to only count once (/browser)
    if (adId) {
      //console.log('AdID is here, with value:' + adId);
      // Check if the ad ID is already present in the array
      if (phoneNumberViewedAtAdID.includes(adId)) {
        // Ad ID already exists in the array, do not show the full phone number
        // console.log('Ad ID already exists in the array');
        setShowFullPhoneNumber(true);
      } else {
        // console.log('Ad ID does not exist in the array');
        // Ad ID does not exist in the array, show the full phone number
        // Add the ad ID to the array in local storage
        //setShowFullPhoneNumber(true);
        phoneNumberViewedAtAdID.push(adId);
        localStorage.setItem(
          'phoneNumberViewedAtAdID',
          JSON.stringify(phoneNumberViewedAtAdID)
        );
      }
    }
  }, []);

  useEffect(() => {
    if (property.price) {
      getPriceInEUR(property.price).then((response) => {
        setPriceInEur(response);
      });
    }

    if (property.price) {
      getRelatedProperties(
        lang,
        property.latitude,
        property.longitude,
        property.price,
        property.typeId,
        property.id
      ).then((response) => {
        setRelatedProperties(response);
      });
    }

    if (property.typeId != 0) {
      if (property.typeId === 9 || property.typeId === 13) {
        setPropertySizeString('Épület(ek) alapterülete');
      } else if (property.typeId === 10) {
        setPropertySizeString('Esetleges építmény területe');
      }
    }
  }, [property]);

  useEffect(() => {
    // console.log(
    //   'UserID changed useEFF to: ' + userID + 'while propertyID is: ' + id
    // );
    if (userID && id) {
      getIsFavoriteByPropertyID(lang, userID, id).then((response) => {
        // console.log('USER FAVORITES RESP ->');
        //  console.log(response);

        if (response) {
          setIsFavorited(response);
        }
      });
    }
  }, [userID]);

  let mergedList;
  useEffect(() => {
    const mergeHelper = new Map(extra_icons.map((x) => [x.id, x]));

    for (const x of propertyExtras) {
      if (mergeHelper.has(x.id)) {
        const item = mergeHelper.get(x.id);
        mergeHelper.set(x.id, { ...item, ...x });
      } else {
        mergeHelper.set(x.id, x);
      }
    }
    const mergedListArray = [...mergeHelper.values()];
    // For sorted array
    // const mergedSortedList = [...mergeHelper.values()].sort((a, b) => a.id - b.id);
    // console.log(mergedListArray);
    mergedList = mergedListArray;
  }, [propertyExtras]);

  function closeModalContact() {
    setIsOpenModalContact(false);
  }

  function closeModalEmailSent() {
    setIsOpenModalEmailSent(false);
  }

  function openModalAmenities() {
    setIsOpenModalContact(true);
  }

  function openModalEmailSent() {
    setIsOpenModalEmailSent(true);
  }

  function openModalUserNotLoggedIn() {
    setIsOpenModalUserNotLoggedIn(true);
  }

  function closeModalUserNotLoggedIn() {
    setIsOpenModalUserNotLoggedIn(false);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const onEmailSentHandler = () => {
    closeModalContact();
    openModalEmailSent();
  };

  const getPhoneNumber = () => {
    if (propertyAgentPhone) {
      if (showFullPhoneNumber) {
        return propertyAgentPhone;
      } else {
        return `${propertyAgentPhone.substring(
          0,
          propertyAgentPhone.length - 3
        )}`;
      }
    }
  };

  const handleShowPhoneNumberClick = () => {
    setShowFullPhoneNumber(true);

    phoneNumberViewedAtAdID.push(adId);
    localStorage.setItem(
      'phoneNumberViewedAtAdID',
      JSON.stringify(phoneNumberViewedAtAdID)
    );
    addAdConversion('phone_viewed', adId, 'hu');
    addUserDailyConversion(adId, 2);
  };

  const containsNumber = /\d/;

  const renderSectionMainCard = () => {
    return (
      <div className='listingSection__wrap !space-y-6'>
        {/* 1 */}
        <div className='flex flex-wrap justify-start items-center'>
          {/* <Badge name={property.type} /> */}
          {property.status && (
            <Badge
              className='mr-4 mt-4'
              name={
                <div className='flex items-center'>
                  <i className=' text-sm las la-tag'></i>
                  <span className='ml-1'>{property.status}</span>
                </div>
              }
              color={'turquoise'}
            />
          )}
          {property.conditions && (
            <Badge
              className='mr-4 mt-4'
              // className='justify-self-start'
              name={
                <div className='flex items-center'>
                  <i className=' text-sm las la-home'></i>
                  <span className='ml-1'>{property.conditions}</span>
                </div>
              }
              // color='yellow'
              color={'turquoise_2'}
            />
          )}
          {property.type && !property.all_type && (
            <Badge
              className='mr-4 mt-4'
              // className='justify-self-start'
              name={
                <div className='flex items-center'>
                  <i className=' text-sm las la-home'></i>
                  <span className='ml-1'>{property.type}</span>
                </div>
              }
              color={'turquoise_3'}
            />
          )}
          {property.type && property.all_type && (
            <Badge
              className='mr-4 mt-4'
              // className='justify-self-start'
              name={
                <div className='flex items-center'>
                  <i className=' text-sm las la-home'></i>
                  <span className='ml-1'>{property.all_type}</span>
                </div>
              }
              color={'turquoise_4'}
            />
          )}

          {/* <LikeSaveBtns /> */}
          <div className='flex ml-auto items-center'>
            <ShareFavoriteBtn
              isFavorited={isFavorited}
              onFavoriteChange={(isFavorited) => {
                if (isLoggedIn && userID) {
                  setIsFavorited(isFavorited);
                  if (isFavorited === true) {
                    addFavoriteByID(lang, userID, id);
                    addAdConversion('favorites_added', id, lang);
                    addUserDailyConversion(id, 1);
                  } else if (isFavorited === false) {
                    // console.log('Delete favorite with id:' + id);
                    deleteFavoriteByID(lang, userID, id);
                    addAdConversion('favorites_removed', id, lang);
                    addUserDailyConversion(id, 4);
                  }
                  //User is NOT logged in
                } else {
                  // console.log('User in not logged in');
                  openModalUserNotLoggedIn();
                }
              }}
            />
          </div>
        </div>

        {/* 2 */}
        <h2 className='text-2xl sm:text-3xl lg:text-4xl font-semibold'>
          {/* @ts-ignore */}
          {property.title}
        </h2>

        {/* 3 */}
        <div className='flex items-center space-x-4'>
          {/* <StartRating />
          <span>·</span> */}
          <span>
            <i className='las la-map-marker-alt'></i>
            <span className='ml-1'> {property.city}</span>
          </span>
        </div>

        {/* 4 */}
        {/* <div className='flex flex-col items-center'>
          <Avatar sizeClass='h-36 w-36' radius='rounded-full' />
          <span className='ml-2.5 text-neutral-500 dark:text-neutral-400 mt-6'>
            Hosted by
            <span className='text-neutral-900 dark:text-neutral-200 font-medium'>
              Kevin Francis
            </span>
          </span>
        </div> */}

        {/* 5 */}
        <div className='w-full border-b border-neutral-100 dark:border-neutral-700' />

        {/* 6 */}
        {/* <div className='flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300'> */}
        <div className='flex items-center justify-between text-sm text-neutral-700 dark:text-neutral-300'>
          {property.size != 0 && property.size != ' m²' && (
            <div className='flex items-center space-x-3 '>
              <i className='las la-expand-arrows-alt text-2xl'></i>
              <span className=''>
                {property.size} <span className='hidden'></span>
              </span>
            </div>
          )}
          {property.beds && (
            <div className='flex items-center space-x-1 sm:space-x-3'>
              <i className='las la-bed text-2xl'></i>
              <span className=' '>
                {property.beds + ' '}
                {property.half_beds != 0 && ' + ' + property.half_beds + ' '}
                <span className='hidden sm:inline-block'>szoba</span>
              </span>
            </div>
          )}
          {property.baths && (
            <div className='flex items-center space-x-1 sm:space-x-3'>
              <i className='las la-bath text-2xl'></i>
              <span className=''>
                {property.baths + ' '}
                <span className='hidden sm:inline-block'>fürdőszoba</span>
              </span>
            </div>
          )}
          {property.garages != 0 && (
            <div className='flex items-center space-x-1 sm:space-x-3'>
              <i className='las la-warehouse text-2xl'></i>
              <span className=' '>
                {property.garages + ' '}
                <span className='hidden sm:inline-block'>garázs</span>
              </span>
            </div>
          )}
          {property.garage_spaces != 0 && (
            <div className='flex items-center space-x-1 sm:space-x-3'>
              <i className='las la-warehouse text-2xl'></i>
              <span className=' '>
                {property.garage_spaces + ' '}
                <span className='hidden sm:inline-block'>
                  garázs férőhelyek
                </span>
              </span>
            </div>
          )}
          {property.floor != 0 && (
            <div className='flex items-center space-x-1 sm:space-x-3'>
              <i className='las la-caret-square-up text-2xl'></i>
              <span className=' '>
                {property.floor + ' '}
                <span className='hidden sm:inline-block'>szintes</span>
              </span>
            </div>
          )}
          {property.floor_located != 0 && (
            <div className='flex items-center space-x-3'>
              <i className='las la-caret-square-up text-2xl'></i>
              <span className=' '>
                {property.floor_located + '. '}
                <span className='hidden sm:inline-block'>emelet</span>
              </span>
            </div>
          )}
        </div>

        <div className='w-full border-b border-neutral-100 dark:border-neutral-700' />
        <div className='flow-root'>
          <div className='text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4'>
            {/* <div className='p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg'>
              <span>Éves áramfogyasztás kWh-ban</span>
              <span>1234 kWh</span>
            </div> */}

            <div className='p-2 flex justify-between items-center space-x-4 rounded-lg'>
              <div className='listingSection__wrap'>
                <div>
                  {/* <h2 className='text-2xl font-semibold'>Extrák</h2> */}
                  <span className='block mt-2 text-neutral-500 dark:text-neutral-400 font-semibold'>
                    {property.status && property.status + ' '}
                    {property.type && !property.all_type && property.type}
                    {property.type && property.all_type && property.all_type}
                  </span>
                </div>
                <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
                <div className='grid grid-cols-1 sm:grid-cols-2'>
                  {property.conditions && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Ingatlan állapota</span>
                      <span className='font-bold'>{property.conditions}</span>
                    </div>
                  )}
                  {property.houseinnerconditions && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Ház állapota belül</span>
                      <span className='font-bold'>
                        {property.houseinnerconditions}
                      </span>
                    </div>
                  )}
                  {property.houseouterconditions && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Ház állapota kívűl</span>
                      <span className='font-bold'>
                        {property.houseouterconditions}
                      </span>
                    </div>
                  )}
                  {property.construction_year != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Építés éve</span>
                      <span className='font-bold'>
                        {property.construction_year}
                      </span>
                    </div>
                  )}
                  {property.plot_buildability != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Telek beépíthetősége</span>
                      <span className='font-bold'>
                        {property.plot_buildability} %
                      </span>
                    </div>
                  )}
                  {property.floor_space_index != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Szintterületi mutató</span>
                      <span className='font-bold'>
                        {property.floor_space_index} m²
                      </span>
                    </div>
                  )}
                  {property.plot_slope != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Telek lejtése</span>
                      <span className='font-bold'>{property.plot_slope} %</span>
                    </div>
                  )}
                  {property.plot_orientation_slope != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Telek égtáji lejtése</span>
                      <span className='font-bold'>
                        {property.plot_orientation_slope}
                      </span>
                    </div>
                  )}

                  {property.plot_shape != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Telek alakja</span>
                      <span className='font-bold'>{property.plot_shape}</span>
                    </div>
                  )}

                  {property.plot_occupied_size != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Telek befoglaló mérete</span>
                      <span className='font-bold'>
                        {property.plot_occupied_size}
                      </span>
                    </div>
                  )}

                  {property.plot_street_front_size != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Telek utcafronti mérete</span>
                      <span className='font-bold'>
                        {property.plot_street_front_size}
                      </span>
                    </div>
                  )}

                  {property.plot_vegetation != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Telek növényzete</span>
                      <span className='font-bold'>
                        {property.plot_vegetation}
                      </span>
                    </div>
                  )}

                  {property.plot_soil_type != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Talaj szerkezete</span>
                      <span className='font-bold'>
                        {property.plot_soil_type}
                      </span>
                    </div>
                  )}

                  {property.plot_parcel != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Új parcellázású</span>
                      <span className='font-bold'>{property.plot_parcel}</span>
                    </div>
                  )}

                  {property.plot_view != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Panorámás kilátás</span>
                      <span className='font-bold'>{property.plot_view}</span>
                    </div>
                  )}

                  {property.bath_wc && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Fürdő és WC</span>
                      <span className='font-bold'>{property.bath_wc}</span>
                    </div>
                  )}
                  {property.comfort && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Komfort</span>
                      <span className='font-bold'>{property.comfort}</span>
                    </div>
                  )}
                  {property.light && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Fényviszonyok</span>
                      <span className='font-bold'>{property.light}</span>
                    </div>
                  )}
                  {property.orientation && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Tájolás</span>
                      <span className='font-bold'>{property.orientation}</span>
                    </div>
                  )}
                  {property.floor && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Szintek száma</span>
                      <span className='font-bold'>{property.floor}</span>
                    </div>
                  )}
                  {property.view && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Kilátás</span>
                      <span className='font-bold'>{property.view}</span>
                    </div>
                  )}
                  {property.floor_located && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Ezen a szinten található</span>
                      <span className='font-bold'>
                        {property.floor_located}
                      </span>
                    </div>
                  )}
                  {property.balcony_size != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Erkély területe</span>
                      <span className='font-bold'>{property.balcony_size}</span>
                    </div>
                  )}
                  {property.inner_height != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Belmagasság</span>
                      <span className='font-bold'>
                        {property.inner_height + ' cm'}
                      </span>
                    </div>
                  )}
                  {property.attic && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Tetőtéri</span>
                      <span className='font-bold'>{property.attic}</span>
                    </div>
                  )}
                  {property.wall && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Falazat típusa</span>
                      <span className='font-bold'>{property.wall}</span>
                    </div>
                  )}
                  {property.parking && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Parkolás</span>
                      <span className='font-bold'>{property.parking}</span>
                    </div>
                  )}
                  {property.garages != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Garázsok száma</span>
                      <span className='font-bold'>{property.garages}</span>
                    </div>
                  )}
                  {property.move_in && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Beköltözhető</span>
                      <span className='font-bold'>{property.move_in}</span>
                    </div>
                  )}
                  {property.furnished && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Bútorozott</span>
                      <span className='font-bold'>{property.furnished}</span>
                    </div>
                  )}
                  {property.attic_buildability != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Tetőtér beépíthetősége</span>
                      <span className='font-bold'>
                        {property.attic_buildability}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='p-2 flex justify-between items-center space-x-4 rounded-lg'>
              <div className='listingSection__wrap'>
                <div>
                  {/* <h2 className='text-2xl font-semibold'>Extrák</h2> */}
                  <span className='block mt-2 text-neutral-500 dark:text-neutral-400 font-semibold'>
                    {'Méretek és egyéb adatok'}
                  </span>
                </div>
                <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
                <div className='grid grid-cols-1 sm:grid-cols-2'>
                  {containsNumber.test(property.size) &&
                    property.size !== '0 m²' &&
                    property.size != 0 && (
                      <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                        <span>{propertySizeString}</span>
                        <span className='font-bold'>{property.size}</span>
                      </div>
                    )}
                  {property.plot_size !== 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Telek területe</span>
                      <span className='font-bold'>{property.plot_size}</span>
                    </div>
                  )}
                  {property.price_m2 != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Négyzetméter ár</span>
                      <span className='font-bold'>
                        {property.price_m2 + ' /m²'}
                      </span>
                    </div>
                  )}
                  {property.terrace_size != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Terasz területe</span>{' '}
                      <span className='font-bold'>{property.terrace_size}</span>
                    </div>
                  )}
                  {property.beds != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Szobák száma</span>
                      <span className='font-bold'>{property.beds}</span>
                    </div>
                  )}
                  {property.half_beds != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Félszobák száma</span>
                      <span className='font-bold'>{property.half_beds}</span>
                    </div>
                  )}
                  {property.baths && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Fürdőszobák száma</span>
                      <span className='font-bold'>{property.baths}</span>
                    </div>
                  )}
                  {property.garage_spaces !== 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Garázs férőhelyek</span>
                      <span className='font-bold'>
                        {property.garage_spaces}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='p-2 flex justify-between items-center space-x-4 rounded-lg'>
              <div className='listingSection__wrap'>
                <div>
                  {/* <h2 className='text-2xl font-semibold'>Extrák</h2> */}
                  <span className='block mt-2 text-neutral-500 dark:text-neutral-400 font-semibold'>
                    {'Fenntarthatóság'}
                  </span>
                </div>
                <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
                <div className='grid grid-cols-1 sm:grid-cols-2'>
                  {property.insulation != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Szigetelés</span>
                      <span className='font-bold'>
                        {property.insulation == null ? 'Nincs' : 'Van'}
                      </span>
                    </div>
                  )}
                  {property.solar_panel != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Napelem</span>
                      <span className='font-bold'>
                        {property.solar_panel == null ? 'Nincs' : 'Van'}
                      </span>
                    </div>
                  )}
                  {property.electricity != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Elektromos áram</span>
                      <span className='font-bold'>{property.electricity}</span>
                    </div>
                  )}
                  {property.water != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Víz</span>
                      <span className='font-bold'>{property.water}</span>
                    </div>
                  )}
                  {property.gas != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Földgáz</span>
                      <span className='font-bold'>{property.gas}</span>
                    </div>
                  )}
                  {property.canal != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Csatorna</span>
                      <span className='font-bold'>{property.canal}</span>
                    </div>
                  )}
                  {property.common_cost != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Közös költség</span>
                      <span className='font-bold'>
                        {property.common_cost + ' /hó'}
                      </span>
                    </div>
                  )}
                  {property.operating_cost != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Üzemeltetési díj</span>
                      <span className='font-bold'>
                        {property.operating_cost + ' /hó'}
                      </span>
                    </div>
                  )}
                  {property.gas_monthly != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Havi gázfogyasztás</span>
                      <span className='font-bold'>
                        {property.gas_monthly + ' m³ /hó'}
                      </span>
                    </div>
                  )}
                  {property.electricity_monthly != 0 && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Havi áramfogyasztás</span>
                      <span className='font-bold'>
                        {property.electricity_monthly + ' kWh /hó'}
                      </span>
                    </div>
                  )}
                  {property.heating && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Fűtés típusa</span>
                      <span className='font-bold'>{property.heating}</span>
                    </div>
                  )}
                  {property.heating_secondary && (
                    <div className='px-4 py-3 flex justify-between items-center space-x-4 odd:bg-neutral-100 sm:odd:bg-transparent sm:odd:border-r border-gray-500'>
                      <span>Fűtés másodlagos típusa</span>
                      <span className='font-bold'>
                        {property.heating_secondary}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* {property.all_type && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-l'>
                <span>Ingatlan kategóriája</span>
                <span className='text-right'>{property.all_type}</span>
              </div>
            )} */}

            {propertyExtras.length !== 0 && (
              <div className='p-4 flex justify-between items-center space-x-4 rounded-lg'>
                <div className='listingSection__wrap'>
                  <div>
                    {/* <h2 className='text-2xl font-semibold'>Extrák</h2> */}
                    <span className='block mt-2 text-neutral-500 dark:text-neutral-400 font-semibold'>
                      További, az ingatlanhoz járó felszereltség
                    </span>
                  </div>
                  <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
                  <div className='grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 '>
                    {propertyExtras
                      .filter((_, i) => i < 30)
                      .map((item) => (
                        <div
                          key={item.title}
                          className='flex items-center space-x-3'
                        >
                          <i className='las la-check-circle text-2xl'></i>
                          <span>{item.title}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSectionDescription = () => {
    return (
      <>
        {property.description && (
          <div className='listingSection__wrap'>
            <h2 className='text-2xl font-semibold'>Ingatlan leírása</h2>
            <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
            <div className='text-neutral-6000 dark:text-neutral-300'>
              <span style={{ whiteSpace: 'pre-line' }}>
                {property.description}
              </span>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderContact = () => {
    return (
      <Transition appear show={isOpenModalContact} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={closeModalContact}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block py-8 h-screen w-full'>
                <div className='inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl'>
                  <div className='relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center'>
                    <h3
                      className='text-lg font-medium leading-6 text-gray-900'
                      id='headlessui-dialog-title-70'
                    >
                      Kapcsolatfelvétel
                    </h3>
                    <span className='absolute left-3 top-3'>
                      <ButtonClose onClick={closeModalContact} />
                    </span>
                  </div>
                  <div className='overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200'>
                    <div className='p-0'>
                      {/* <div className='listingSectionSidebar__wrap shadow-xl mb-24 p-8'> */}
                      <div className='w-full flex flex-col rounded-2xl dark:border-neutral-700 space-y-6 sm:space-y-8 pb-10 p-2 sm:p-4 xl:p-8'>
                        <div className='flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 '>
                          <div className='min-h-full'>
                            <div className='text-center text-xl font-medium mb-12 flex flex-col justify-evenly h-full'>
                              <div className='mt-0 sm:mt-0'>
                                <Avatar
                                  sizeClass='h-64 w-64 self-center'
                                  radius='rounded-2xl'
                                  imgUrl={
                                    propertyAgent.user_image &&
                                    `${appConfig.URL}images/${propertyAgent.user_image}`
                                  }
                                />
                                <div className='text-m font-semibold mt-2'>
                                  {propertyAgent.user_name}
                                </div>
                                {propertyAgent.user_role <= 2 ? (
                                  <span className='font-normal'>
                                    Ingatlan referens
                                  </span>
                                ) : (
                                  <span className='font-normal'>Hirdető</span>
                                )}
                              </div>
                              {propertyAgent.phone_enabled === 1 &&
                                propertyAgent.user_phone && (
                                  <div className='mt-12 sm:mt-12'>
                                    <div className=''>
                                      <div className=''>
                                        <span className=''>
                                          {'Hívjon bizalommal'}
                                        </span>
                                      </div>
                                      <div className='mt-4'>
                                        <div>
                                          <a href={`tel:${propertyAgentPhone}`}>
                                            {getPhoneNumber()}
                                          </a>
                                          {!showFullPhoneNumber && (
                                            <button
                                              onClick={
                                                handleShowPhoneNumberClick
                                              }
                                            >
                                              <span className='bg-[#17BED0] text-neutral-50 rounded-full p-2 text-sm sm:text-base'>
                                                Teljes szám felfedése
                                              </span>
                                            </button>
                                          )}
                                        </div>
                                        {/* <span className='sm:bg-[#17BED0] sm:text-neutral-50 sm:rounded-full sm:m-4 sm:p-3 sm:text-base'>
                                      {/* {propertyAgent.user_phone
                                        ? propertyAgentPhone
                                        : '+36 20 619 9696'} 
                                    </span> */}
                                      </div>

                                      <a
                                        href={
                                          propertyAgent.user_phone
                                            ? 'tel:+' + propertyAgent.user_phone
                                            : 'tel:+36206199696'
                                        }
                                        className='mt-2 block sm:hidden'
                                      >
                                        <ButtonPrimary
                                          type='submit'
                                          className='mt-2'
                                        >
                                          <i className='text-xl las la-phone-alt'></i>
                                          <span className='ml-2'>
                                            {'Érintse meg a híváshoz'}
                                          </span>
                                        </ButtonPrimary>
                                      </a>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>

                          {userID && isLoggedIn ? (
                            <div className=''>
                              <div className='text-center text-xl font-medium mb-12'>
                                Vagy küldjön üzenetet
                              </div>
                              <ContactForm
                                className='p-4 sm:p-0'
                                userId={userID}
                                propertyId={property.id}
                                agentEmailAddress={propertyAgent.user_email}
                                reference={property.reference}
                                onEmailSent={onEmailSentHandler}
                              />
                            </div>
                          ) : (
                            <div className='flex items-center'>
                              <div className='text-center listingSection__wrap'>
                                <div className='text-xl font-medium mb-12'>
                                  Üzenet küldése
                                </div>
                                <div className='px-6 text-md font-medium mb-12'>
                                  Üzenet küldéséhez jelentkezz be vagy
                                  regisztrálj
                                </div>

                                <div className='flex flex-row items-center justify-center'>
                                  <ButtonSecondary
                                    href={`/signup`}
                                    className='mx-2 my-4 md:my-0 md:ml-0 px-4 py-2 bg-[#17BED0] hover:bg-[#17BED0] text-neutral-50 text-lg'
                                    targetBlank={true}
                                  >
                                    Regisztráció
                                  </ButtonSecondary>
                                  <ButtonSecondary
                                    href={`/signin`}
                                    className='mx-2 my-4 md:my-0 md:ml-0 px-4 py-2 text-lg'
                                    targetBlank={true}
                                  >
                                    Bejelentkezés
                                  </ButtonSecondary>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderEmailSentPopup = () => {
    return (
      <Transition appear show={isOpenModalEmailSent} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={closeModalEmailSent}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block py-8 h-screen w-full'>
                <div className='inline-flex flex-col text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl'>
                  <div className='relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center'>
                    <h3
                      className='text-lg font-medium leading-6 text-gray-900'
                      id='headlessui-dialog-title-70'
                    >
                      E-mail sikeresen elküldve
                    </h3>
                    <span className='absolute left-3 top-3'>
                      <ButtonClose onClick={closeModalEmailSent} />
                    </span>
                  </div>
                  <div className='overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200'>
                    <div className='sm:p-0 p-12'>
                      {/* <div className='listingSectionSidebar__wrap shadow-xl mb-24 p-8'> */}
                      <div className=' w-full flex flex-col rounded-2xl dark:border-neutral-700 space-y-6 sm:space-y-8 pb-10 p-2 sm:p-4 xl:p-8'>
                        {'Hamarosan felveszzük Önnel a kapcsolatot.'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderUserNotLoggedInPopup = () => {
    return (
      <Transition appear show={isOpenModalUserNotLoggedIn} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={closeModalUserNotLoggedIn}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block max-w-sm py-8 h-screen w-full'>
                <div className='inline-flex flex-col text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl max-w-full'>
                  <div className='relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center'>
                    <h3
                      className='px-2 text-lg font-medium leading-6 text-gray-900'
                      id='headlessui-dialog-title-70'
                    >
                      Ingatlan kedvencekhez adása
                    </h3>
                    <span className='absolute left-3 top-3'>
                      <ButtonClose onClick={closeModalUserNotLoggedIn} />
                    </span>
                  </div>
                  <div className='overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200'>
                    <div className='sm:p-6 p-6'>
                      {/* <div className='listingSectionSidebar__wrap shadow-xl mb-24 p-8'> */}
                      <div className='w-full flex flex-col rounded-2xl dark:border-neutral-700 space-y-6 sm:space-y-8 p-2 sm:p-4 xl:p-4'>
                        {
                          'Az ingatlan kedvencekhez adásához kérlek jelentkezz be.'
                        }
                      </div>

                      <div className='flex flex-row items-center justify-center'>
                        <ButtonSecondary
                          href={`/signup`}
                          className='mx-2 my-4 md:my-0 md:ml-0 px-4 py-2 bg-[#17BED0] hover:bg-[#17BED0] text-neutral-50 text-lg'
                          targetBlank={true}
                        >
                          Regisztráció
                        </ButtonSecondary>
                        <ButtonSecondary
                          href={`/signin`}
                          className='mx-2 my-4 md:my-0 md:ml-0 px-4 py-2 text-lg'
                          targetBlank={true}
                        >
                          Bejelentkezés
                        </ButtonSecondary>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className='listingSection__wrap'>
        {/* HEADING */}
        <div>
          <h2 className='text-2xl font-semibold'>További információ </h2>
          {/* <span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
            Prices may increase on weekends or holidays
          </span> */}
        </div>
        <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
        {/* CONTENT */}
        <div className='flow-root'>
          <div className='text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4'>
            <div className='p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg'>
              <span>Éves áramfogyasztás kWh-ban</span>
              <span>1234 kWh</span>
            </div>
            {property.view && (
              <div className='p-4 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Kilátás</span> <span>{property.view}</span>
              </div>
            )}
            {property.parking && (
              <div className='p-4 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Parkolás</span> <span>{property.parking}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSectionMap = () => {
    return (
      <div className='listingSection__wrap'>
        {/* HEADING */}
        <div>
          <h2 className='text-2xl font-semibold'>Elhelyezkedés</h2>
          <span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
            {property.address}
          </span>

          {property.staircase != 0 && (
            <span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
              {'Lépcsőház: ' + property.staircase}
            </span>
          )}

          {property.door != 0 && (
            <span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
              {'Ajtó: ' + property.door}
            </span>
          )}

          {/* {property.hrsz != 0 && (
            <span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
              {'HRSZ: ' + property.hrsz}
            </span>
          )} */}
        </div>
        <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />

        {/* MAP */}
        <div className='aspect-w-5 aspect-h-5 sm:aspect-h-3'>
          <div className='rounded-xl overflow-hidden'>
            {/* <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY',
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={15}
              defaultCenter={{
                lat: 55.9607277,
                lng: 36.2172614,
              }}
            >
              <LocationMarker lat={55.9607277} lng={36.2172614} />
            </GoogleMapReact> */}
            {/* <div style={'width: 100%'> */}
            {/* <iframe
                width='100%'
                height='600'
                frameborder='0'
                scrolling='no'
                marginheight='0'
                marginwidth='0'
                src={`https://maps.google.com/maps?q=${property.latitude},${property.longitude}&amp;hl=en&amp;z=14&amp;output=embed`}
              >
                <a href='https://www.maps.ie/distance-area-calculator.html'>
                  area maps
                </a>
              </iframe> */}
            <div className='w-full h-full'>
              <iframe
                className='w-full h-full aspect-video'
                // className='ev-map'
                //height='470'
                src={`https://maps.google.com/maps?q=${property.latitude},${property.longitude}&hl=en&z=14&output=embed`}
              ></iframe>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  };

  const renderSectionVirtualTour = () => {
    if (property.v360) {
      return (
        <div className='listingSection__wrap'>
          <h2 className='text-2xl font-semibold'>Virtuális séta</h2>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />
          <iframe
            src={property.v360}
            width='100%'
            height='500'
            allowFullScreen='true'
          ></iframe>
        </div>
      );
    }
  };

  // <?php if(!empty($itemDetails['pt_video'])): ?>
  // <h5 class="uk-heading-line uk-text-bold"><span><?php echo echoOutput($translation['tr_72']); ?></span></h5>
  // <button class="uk-button uk-button-default uk-width-1-1 uk-border-rounded uk-margin" type="button" uk-toggle="target: +"><i class="fas fa-play uk-margin-small-right"></i> <?php echo echoOutput($translation['tr_74']); ?></button>
  // <iframe src="https://www.youtube-nocookie.com/embed/<?php echo echoOutput($itemDetails['pt_video']); ?>?autoplay=0&amp;showinfo=0&amp;rel=0&amp;modestbranding=0&amp;playsinline=1" width="1920" height="1080" frameborder="0" allowfullscreen uk-responsive uk-video="automute: false" hidden></iframe>
  // <?php endif; ?>

  const renderSectionYoutubeEmbed = () => {
    if (property.video_url) {
      return (
        <div className='listingSection__wrap'>
          <h2 className='text-2xl font-semibold'>Videó bemutató</h2>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${property.video_url}`}
            width='100%'
            height='500'
            allowfullscreen='true'
          ></iframe>
        </div>
      );
    }
  };

  const renderSectionTiktokEmbed = () => {
    if (property.tiktok_id) {
      return (
        <div className='listingSection__wrap'>
          <h2 className='text-2xl font-semibold'>Tiktok videó</h2>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />
          <iframe
            src={`https://www.tiktok.com/embed/v2/${property.tiktok_id}`}
            width='100%'
            height='740'
            allowfullscreen='true'
          ></iframe>
          {/* {getTiktokEmbedHTML()} */}
        </div>
      );
    }
  };

  const renderSectionSimilarProperties = () => {
    return (
      <>
        {relatedProperties && relatedProperties.length !== 0 && (
          <>
            {/* <div className='listingSection__wrap !space-y-6 mb-12'> */}
            <div className='w-full flex flex-col sm:rounded-2xl space-y-6 sm:space-y-8 sm:mb-24 xl:mb-24 px-0 sm:p-4 xl:p-8 !space-y-6 mb-12'>
              {/* <BackgroundSection className='bg-sky-100' /> */}
              {/* <BackgroundSection className=' bg-[#F3F5FF]' /> */}
              {/* <BackgroundSection className=' bg-[#0891B2]/20' /> */}
              <BackgroundSection className=' bg-neutral-100' />

              {/* <BackgroundSection className='bg-orange-50 dark:bg-black dark:bg-opacity-20 ' /> */}
              <SectionRelatedSlider
                property={relatedProperties}
                uniqueClassName='PageHome_s1'
                categoryCardType='card4'
                itemPerRow={4}
                sliderStyle='style1'
                uniqueClassName='PageHome_s2'
              />
            </div>
          </>
        )}
      </>
    );
  };

  const renderSidebar = () => {
    return (
      <div className='sm:p-0 p-0'>
        <div className='listingSectionSidebar__wrap shadow-xl mb-24 p-8'>
          {/* <div className='listingSectionSidebar__wrap shadow-xl mb-24 sm:w-full w-4/5'> */}
          <a
            className='self-center'
            target='_blank'
            href={`${appConfig.URL}agent_details.php?id=${propertyAgent.user_id}`}
          >
            <Avatar
              sizeClass='h-36 w-36 self-center'
              radius='rounded-full'
              imgUrl={
                propertyAgent.user_image &&
                `${appConfig.URL}images/${propertyAgent.user_image}`
              }
            />
          </a>

          <div className='flex flex-col text-center'>
            {propertyAgent.user_role <= 2 ? (
              <span> Az Ön ingatlan értékesítője</span>
            ) : (
              <span> Kapcsolattartó</span>
            )}

            <span className='font-medium'>{propertyAgent.user_name}</span>
          </div>
          <div className='border-b border-neutral-200 dark:border-neutral-700'></div>
          <div className='flex justify-between '>
            <span>Ár HUF</span>
            <span>{property.price}</span>
            {property.statusId === 2 && (
              <span className='ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400'>
                /hó
              </span>
            )}
          </div>
          <div className='flex justify-between text-bold'>
            <span>Ár EUR</span>
            <span className='semi-bold'>{priceInEur}</span>
            {property.statusId === 2 && (
              <span className='ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400'>
                /hó
              </span>
            )}
          </div>
          {/* SUBMIT */}

          {/* <a className='' href={`${appConfig.URL}contact-us-3`} target='_blank'>
            <ButtonPrimary>Kapcsolat</ButtonPrimary>
          </a> */}
          {/* <Link
            to={`${appConfig.URL}contact-us-3`}
            className='nc-Button text-neutral-50 relative h-auto inline-flex items-center justify-center rounded-full bg-[#0891B2] py-3 font-medium'
          >
            Kapcsolat
          </Link> */}

          <ButtonPrimary className='bg-[#af134c]' onClick={openModalAmenities}>
            Kapcsolat
          </ButtonPrimary>

          <a
            href={`${appConfig.URL}agent_details.php?id=${propertyAgent.user_id}`}
            target='_blank'
            className='nc-Button relative h-auto inline-flex items-center justify-center rounded-full border border-neutral-200 transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6 ttnc-ButtonSecondary disabled:bg-opacity-70 text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0'
          >
            Hirdető további ingatlanjai
          </a>
        </div>

        {/* <div className='min-h-screen text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. *
          <span
            className='inline-block h-screen align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            className='inline-block py-8 h-screen w-full'
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full'>
              <div className='relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center'>
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-gray-900'
                >
                  További szűrés
                </Dialog.Title>
                <span className='absolute left-3 top-3'>
                  <ButtonClose onClick={closeModalMoreFilter} />
                </span>
              </div>

              <div className='flex-grow overflow-y-auto'>
                <div className='px-10 divide-y divide-neutral-200 dark:divide-neutral-800'></div>
              </div>

              <div className='p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between'>
                <ButtonThird
                  onClick={() => {
                    //closeModalMoreFilterOnClear();
                  }}
                  sizeClass='px-4 py-2 sm:px-5'
                >
                  Szűrők Törlése
                </ButtonThird>
                <ButtonPrimary
                  onClick={closeModalMoreFilter}
                  sizeClass='px-4 py-2 sm:px-5'
                >
                  Szűrés
                </ButtonPrimary>
              </div>
            </div>
          </Transition.Child>
        </div> */}
      </div>
    );
  };
  if (property && property.response_code === 'active') {
    return (
      <div
        className={`nc-ListingStayDetailPage  ${className}`}
        data-nc-id='ListingStayDetailPage'
      >
        {images.length == 1 && (
          <header className='container 2xl:px-56 rounded-md sm:rounded-xl'>
            <div className='relative z-10 mt-11 flex flex-col lg:flex-row '>
              <div
                className=' rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
                onClick={() => handleOpenModal(0)}
              >
                <NcImage
                  containerClassName='absolute inset-0'
                  className='object-cover w-full h-full rounded-md sm:rounded-xl'
                  src={property.image}
                />
                <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'></div>
              </div>
              {/* Give some space */}
              <div className='sm:h-[600px] h-[300px]'></div>

              <div
                className='absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10'
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
                  />
                </svg>
                <span className='ml-2 text-neutral-800 text-sm font-medium'>
                  Galéria megtekintése
                </span>
              </div>
            </div>
          </header>
        )}
        {images.length >= 2 && (
          <>
            <header className='p-2 sm:container 2xl:px-24 rounded-md sm:rounded-xl'>
              <div className='relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2'>
                <div
                  className='sm:col-span-3 col-span-3 row-span-3 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
                  onClick={() => handleOpenModal(0)}
                >
                  <NcImage
                    containerClassName={`inset-0 ${
                      images.length >= 4 ? 'sm:absolute' : ''
                    }`}
                    className='object-cover w-full h-full rounded-md sm:rounded-xl'
                    src={property.image}
                  />
                  <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'></div>
                </div>

                {images &&
                  images.length > 1 &&
                  images
                    .filter((_, i) => i >= 1 && i < 4)
                    .map((item, index) => (
                      <div
                        key={index}
                        className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                          index >= 2 ? 'block' : ''
                        }`}
                      >
                        <NcImage
                          containerClassName='aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5'
                          className='object-cover w-full h-full rounded-md sm:rounded-xl '
                          src={item}
                        />

                        <div
                          className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer'
                          onClick={() => handleOpenModal(index + 1)}
                        />
                      </div>
                    ))}

                <div
                  className='absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10'
                  onClick={() => handleOpenModal(0)}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-5 w-5'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={1.5}
                      d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
                    />
                  </svg>
                  <span className='ml-2 text-neutral-800 text-sm font-medium'>
                    Galéria megtekintése
                  </span>
                </div>
              </div>
            </header>
          </>
        )}
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={images}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName='nc-ListingExperiencesDetailPage__modalPhotos'
        />

        {/* MAIN */}
        <main className='container relative z-10 mt-6 sm:mt-11 flex flex-col lg:flex-row '>
          {/* CONTENT */}
          <div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10'>
            {renderSectionMainCard()}
            {renderSectionDescription()}
            {renderSectionMap()}
            {renderSectionVirtualTour()}
            {renderSectionYoutubeEmbed()}
            {renderSectionTiktokEmbed()}
            {renderContact()}
            {renderEmailSentPopup()}
            {renderUserNotLoggedInPopup()}
          </div>

          {/* SIDEBAR */}
          <div className='block flex-grow mt-14 lg:mt-0'>
            <div className='sticky top-24'>{renderSidebar()}</div>
          </div>
        </main>

        <div className='container relative z-10 mt-11 mb-12 flex flex-col lg:flex-row'>
          {renderSectionSimilarProperties()}
        </div>

        {/* STICKY FOOTER MOBILE */}
        {!isPreviewMode && (
          <div className='block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20'>
            <div className='container flex items-center justify-between'>
              <span className='text-2xl font-semibold'>
                {property.price}
                {/* IF APARTMENT */}
                {property.statusId === 2 && (
                  <span className='ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400'>
                    /hó
                  </span>
                )}
              </span>
              <ButtonPrimary onClick={openModalAmenities}>
                Kapcsolat
              </ButtonPrimary>
            </div>
          </div>
        )}
      </div>
    );
  } else if (property && property.response_code === 'sold') {
    return (
      <PagePropertyNotActive
        description='Ez az ingatlan már eladásra került.'
        status={property.response_code}
      />
    );
  } else if (property && property.response_code === 'under_acceptance') {
    return (
      <PagePropertyNotActive description='A hirdetés jelenleg ellenőrzés alatt van, kérlek nézz vissza később.' />
    );
  } else if (property && property.response_code === 'no_result') {
    return (
      <PagePropertyNotActive description='Ez a hirdetés sajnos már nincs a rendszerünkben.' />
    );
  } else if (property && property.response_code === 'not_active') {
    return (
      <PagePropertyNotActive description='Ez az ingatlan jelenleg nem aktív.' />
    );
  } else {
    return <></>;
  }
};

export default ListingStayDetailPage;
